import LocalStorageHelper from './LocalStorageHelper';
import NotificationHelper from './NotificationHelper';
import Settings from './Settings';
import { useTranslation } from 'react-i18next';

export default async function mailContract(
  contractDataId: number,
  mailTo: string,
  attachmentFiles: { [id: string]: File | undefined },
): Promise<void> {
  //const { t, i18n } = useTranslation();

  const toBase64 = ( file: File ) =>
    new Promise( ( resolve, reject ) => {
      const reader = new FileReader();
      reader.readAsDataURL( file );
      reader.onload = () => {
        let encoded =
          reader.result?.toString().replace( /^data:(.*,)?/, '' ) ?? '';
        if ( encoded.length % 4 > 0 ) {
          encoded += '='.repeat( 4 - ( encoded.length % 4 ) );
        }
        resolve( encoded );
      };
      reader.onerror = ( error ) => reject( error );
    } );

  const attachmentFilesBase64 = [];
  const attachmentFilesEntries = Object.values( attachmentFiles );

  if ( attachmentFiles ) {
    for ( let i = 0; i < attachmentFilesEntries.length; i++ ) {
      const attachmentFile = attachmentFilesEntries[ i ];
      if ( attachmentFile )
        attachmentFilesBase64.push( {
          filename: attachmentFile.name,
          data: await toBase64( attachmentFile ),
        } );
    }
  }

  fetch( `${ Settings.apiUrl }/mail-by-data/${ contractDataId }`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      Authorization: 'Bearer ' + LocalStorageHelper.get( 'token' ),
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify( {
      mailTo,
      attachmentFiles: attachmentFilesBase64,
    } ),
  } )
    .then( ( resolve ) => {
      if ( !resolve.ok ) {
        throw new Error(
          //t(
          'Document could not be generated.',
          //)
        );
      }

      return resolve.blob();
    } )
    .catch( ( error ) => {
      NotificationHelper.error(
        //t(
        'An error occurred. Please check inputs and try again. ' +
          'If the problem persists, please contact us if the problem persists.',
        //),
        error,
      );
    } );
}
