import { ContractFormData, ContractVariableData } from './ContractData';
import { ContractDefinition } from './ContractDefinition';
import {
  ContractDefinitionEnriched,
  PlaceholderTextElement,
  ContractDefinitionAnswerEnriched,
  ContractDefinitionQuestionEnriched,
} from './ContractDefinitionEnriched';
import NotificationHelper from './NotificationHelper';

class PlaceholderHelper {
  public static parsePlaceholderText(
    text: string | string[] | undefined,
  ): [PlaceholderTextElement[], string[]] {
    if ( text === undefined || text === null ) {
      return [ [], [] ];
    }
    const returnElements: PlaceholderTextElement[] = [];
    const variables: string[] = [];

    if ( !Array.isArray( text ) ) {
      text = [ text ];
    }

    text.forEach( ( text ) => {
      text.split( /(<[qv]_[0-9A-z]*>)/g ).forEach( ( textElement ) => {
        if ( textElement.startsWith( '<q_' ) ) {
          returnElements.push( {
            type: 'question',
            data: textElement.slice( 1, -1 ),
          } );
          variables.push( textElement.slice( 1, -1 ) );
        } else if ( textElement.startsWith( '<v_' ) ) {
          returnElements.push( {
            type: 'variable',
            data: textElement.slice( 1, -1 ),
          } );
          variables.push( textElement.slice( 1, -1 ) );
        } else {
          returnElements.push( { type: 'text', data: textElement } );
        }
      } );

      returnElements.push( { type: 'linebreak', data: '' } );
    } );

    return [ returnElements, variables ];
  }

  public static parseAnswer<C extends ContractDefinitionAnswerEnriched>(
    answer: C,
  ): [C, string[]] {
    let placeholderListShortAnswer: string[] = [];
    let placeholderListLongAnswer: string[] = [];
    const newAnswer = { ...answer };

    [ newAnswer.parsedShortAnswer, placeholderListShortAnswer ] =
      this.parsePlaceholderText( newAnswer.shortAnswer );
    [ newAnswer.parsedLongAnswer, placeholderListLongAnswer ] =
      this.parsePlaceholderText( newAnswer.longAnswer );

    return [
      newAnswer,
      [ ...placeholderListShortAnswer, ...placeholderListLongAnswer ],
    ];
  }

  public static enrichContractDefinitionWithPlaceholders(
    contractDefinitionPlain: ContractDefinition,
  ): void {
    const contractDefinition =
      contractDefinitionPlain as ContractDefinitionEnriched;

    contractDefinition.questions.forEach( ( questionSet ) => {
      Object.entries( questionSet ).forEach( ( [ , question ] ) => {
        [ question.parsedQuestionText, question.questionTextPlaceholderList ] =
          this.parsePlaceholderText( question.questionText );
        [ question.parsedExplanation, question.explanationPlaceholderList ] =
          this.parsePlaceholderText( question.explanation );

        question.dataPlaceholderList = [];
        if (
          question.__component === 'question.single-choice' ||
          question.__component === 'question.multiple-choice'
        ) {
          question.choices.forEach( ( choice, index ) => {
            let placeholderList: string[] = [];

            [ question.choices[ index ], placeholderList ] =
              this.parseAnswer( choice );

            question.dataPlaceholderList.push( ...placeholderList );
          } );
        } else if ( question.__component === 'question.checkbox' ) {
          [ question.data, question.dataPlaceholderList ] = this.parseAnswer(
            question.data as ContractDefinitionAnswerEnriched,
          );
        } else if (
          question.__component === 'question.text-field' ||
          question.__component === 'question.text-area'
        ) {
          try {
            [ question.parsedPlaceholder, question.dataPlaceholderList ] =
              this.parsePlaceholderText( question.placeholder );
          } catch ( e ) {
            NotificationHelper.silentError( 'Malformed question', question );
          }
        } else if ( question.__component === 'question.shares' ) {
          question.parsedData = { shares: [], textTemplate: [] };

          [ question.parsedData.shares, question.dataPlaceholderList ] =
            this.parsePlaceholderText( question.data.shares );
          [ question.parsedData.textTemplate, question.dataPlaceholderList ] =
            this.parsePlaceholderText( question.data.textTemplate );
        } else if ( question.__component === 'question.attachment' ) {
          // everything is already done
        } else if ( question.__component === 'question.attachment-reminder' ) {
          // everything is already done
        } else if ( question.__component === 'question.none' ) {
          // everything is already done
        } else {
          NotificationHelper.silentError(
            // TODO: Translation
            'Unknown question type in PlaceholderHelper.enrichContractDefinitionWithPlaceholders',
            question,
          );
        }
      } );
    } );
  }

  public static isAnyPlaceholderEmpty(
    placeholderIds: string[],
    contractFormData: ContractFormData,
    contractVariableData: ContractVariableData,
  ): boolean {
    if ( placeholderIds !== undefined && placeholderIds.length === 0 ) {
      return false;
    }

    return !placeholderIds.every( ( id ) => {
      if ( id.startsWith( 'q_' ) ) {
        return (
          contractFormData[ id ] !== undefined &&
          contractFormData[ id ].answers !== null
        );
      } else if ( id.startsWith( 'v_' ) ) {
        return (
          contractVariableData[ id ] !== undefined &&
          contractVariableData[ id ].answers !== null
        );
      }

      return false;
    } );
  }

  public static assembleText(
    placeholders: PlaceholderTextElement[] | undefined,
    contractDefinition: ContractDefinitionEnriched,
    contractFormData: ContractFormData,
    contractVariableData: ContractVariableData,
    linebreakSymbol = '\n',
  ): string {
    let returnString = '';

    if ( placeholders === undefined ) {
      return returnString;
    }

    placeholders.forEach( ( placeholder ) => {
      const id = placeholder.data;

      if ( placeholder.type === 'variable' ) {
        returnString += this.resolveVariable(
          contractDefinition,
          contractVariableData,
          id,
        );
      } else if ( placeholder.type === 'question' ) {
        returnString += this.resolveQuestion(
          contractDefinition,
          contractFormData,
          id,
        );
      } else if ( placeholder.type === 'text' ) {
        returnString += placeholder.data;
      } else if ( placeholder.type === 'linebreak' ) {
        returnString += linebreakSymbol;
      }
    } );

    return returnString;
  }

  private static resolveQuestion(
    contractDefinition: ContractDefinition,
    contractFormData: ContractFormData,
    questionId: string,
  ): string {
    if ( contractFormData[ questionId ] === undefined ) {
      return '';
    }

    const questionSet = contractDefinition.questions.find(
      ( questions ) => questions[ questionId ] !== undefined,
    );
    if ( questionSet !== undefined ) {
      const question = questionSet[ questionId ];
      const questionAnswers = contractFormData[ questionId ].answers;
      if ( questionAnswers === null || questionAnswers === undefined ) {
        return '';
      }

      if ( question.__component === 'question.single-choice' ) {
        const choices = question.choices;

        return (
          choices.find( ( choice ) => choice.name === questionAnswers[ 0 ] )
            ?.shortAnswer ?? ''
        );
      } else if (
        question.__component === 'question.text-field' ||
        question.__component === 'question.text-area'
      ) {
        return questionAnswers[ 0 ] as string;
      }
    }

    return '';
  }

  private static resolveVariable(
    contractDefinition: ContractDefinition,
    contractVariableData: ContractVariableData,
    variableId: string,
  ): string {
    if ( contractVariableData[ variableId ] === undefined ) {
      return '';
    }

    const variable = contractDefinition.variables[ variableId ];

    if ( variable.__component === 'variable.text' ) {
      const variableAnswer =
        contractVariableData[ variableId ].answers?.[ 0 ]?.toString();

      if ( variableAnswer !== undefined ) {
        return variable.data[ variableAnswer ].text;
      }
    } else if ( variable.__component === 'variable.addition' ) {
      const returnValue =
        contractVariableData[ variableId ].answers?.[ 0 ]?.toString();

      if ( returnValue !== undefined ) {
        return returnValue;
      }
    }

    return '';
  }

  public static assembleQuestionTexts(
    questionDefinition: ContractDefinitionQuestionEnriched,
    contractDefinition: ContractDefinitionEnriched,
    contractFormData: ContractFormData,
    contractVariableData: ContractVariableData,
  ): ContractDefinitionQuestionEnriched {
    questionDefinition = { ...questionDefinition };

    questionDefinition.questionText = PlaceholderHelper.assembleText(
      questionDefinition.parsedQuestionText,
      contractDefinition,
      contractFormData,
      contractVariableData,
    );
    if ( questionDefinition.parsedExplanation !== undefined ) {
      questionDefinition.explanation = PlaceholderHelper.assembleText(
        questionDefinition.parsedExplanation,
        contractDefinition,
        contractFormData,
        contractVariableData,
      );
    }
    if (
      questionDefinition.__component === 'question.single-choice' ||
      questionDefinition.__component === 'question.multiple-choice'
    ) {
      questionDefinition.choices = [ ...questionDefinition.choices ];

      const questionDefinitionData = questionDefinition.choices;
      questionDefinition.choices.forEach( ( choice, index ) => {
        const answer = { ...questionDefinitionData[ index ] };

        answer.shortAnswer = PlaceholderHelper.assembleText(
          answer.parsedShortAnswer,
          contractDefinition,
          contractFormData,
          contractVariableData,
        );
        answer.longAnswer = PlaceholderHelper.assembleText(
          answer.parsedLongAnswer,
          contractDefinition,
          contractFormData,
          contractVariableData,
        );

        questionDefinitionData[ index ] = answer;
      } );
    } else if ( questionDefinition.__component === 'question.checkbox' ) {
      questionDefinition.data = { ...questionDefinition.data };

      questionDefinition.data = { ...questionDefinition.data };

      questionDefinition.data.shortAnswer = PlaceholderHelper.assembleText(
        questionDefinition.data.parsedShortAnswer,
        contractDefinition,
        contractFormData,
        contractVariableData,
      );
      questionDefinition.data.longAnswer = PlaceholderHelper.assembleText(
        questionDefinition.data.parsedLongAnswer,
        contractDefinition,
        contractFormData,
        contractVariableData,
      );
    } else if (
      questionDefinition.__component === 'question.text-field' ||
      questionDefinition.__component === 'question.text-area'
    ) {
      questionDefinition.placeholder = PlaceholderHelper.assembleText(
        questionDefinition.parsedPlaceholder,
        contractDefinition,
        contractFormData,
        contractVariableData,
      );
    } else if ( questionDefinition.__component === 'question.shares' ) {
      questionDefinition.data.shares = PlaceholderHelper.assembleText(
        questionDefinition.parsedData.shares,
        contractDefinition,
        contractFormData,
        contractVariableData,
      );
    } else if ( questionDefinition.__component === 'question.attachment' ) {
      // everything is already done
    } else if (
      questionDefinition.__component === 'question.attachment-reminder'
    ) {
      // everything is already done
    } else if ( questionDefinition.__component === 'question.none' ) {
      // everything is already done
    } else {
      NotificationHelper.silentError(
        // TODO: Translation
        'Unknown question type in PlaceholderHelper.assembleQuestionTexts',
        questionDefinition,
      );
    }

    return questionDefinition;
  }
}

export default PlaceholderHelper;
