import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DownloadIcon from '@mui/icons-material/Download';
import FilterListIcon from '@mui/icons-material/FilterList';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { visuallyHidden } from '@mui/utils';
import { Button, Grid } from '@mui/material';
import ApiHelper from '../../common/ApiHelper';
import ContactDialog from './ContactDialog';

interface Data {
  key: string;
  alias: string;
  name: string;
  actions: string;
}

function createData(
  key: string,
  alias: string,
  name: string,
  actions: string,
): Data {
  return {
    key,
    alias,
    name,
    actions,
  };
}

function descendingComparator<T>( a: T, b: T, orderBy: keyof T ) {
  if ( b[ orderBy ] < a[ orderBy ] ) {
    return -1;
  }
  if ( b[ orderBy ] > a[ orderBy ] ) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof Data | string | number | symbol>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? ( a, b ) => descendingComparator( a, b, orderBy )
    : ( a, b ) => -descendingComparator( a, b, orderBy );
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(
  array: readonly T[],
  comparator: ( a: T, b: T ) => number,
) {
  const stabilizedThis = array.map( ( el, index ) => [ el, index ] as [T, number] );
  stabilizedThis.sort( ( a, b ) => {
    const order = comparator( a[ 0 ], b[ 0 ] );
    if ( order !== 0 ) {
      return order;
    }
    return a[ 1 ] - b[ 1 ];
  } );
  return stabilizedThis.map( ( el ) => el[ 0 ] );
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'alias',
    numeric: false,
    disablePadding: true,
    label: 'Alias',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'actions',
    numeric: true,
    disablePadding: false,
    label: '',
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => void;
  onSelectAllClick: ( event: React.ChangeEvent<HTMLInputElement> ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead( props: EnhancedTableProps ) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler =
    ( property: keyof Data ) => ( event: React.MouseEvent<unknown> ) => {
      onRequestSort( event, property );
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map( ( headCell ) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler( headCell.id )}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ) )}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
  setContactDialogIsOpen: ( value: boolean ) => void;
}

const EnhancedTableToolbar = ( props: EnhancedTableToolbarProps ) => {
  const { numSelected } = props;
  const { t, i18n } = useTranslation();

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...( numSelected > 0 && {
          bgcolor: ( theme ) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity,
            ),
        } ),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {t( 'numSelected selected', { numSelected } )}
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {t( 'contacts' )}
        </Typography>
      )}
      {numSelected > 0 ? (
        <>
          {/*<Tooltip title="Delete">
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </Tooltip>*/}
        </>
      ) : (
        <Tooltip title="Kontakt hinzufügen">
          <Button
            variant="contained"
            endIcon={<AddIcon />}
            onClick={() => props.setContactDialogIsOpen( true )}
          >
            Neu
          </Button>
        </Tooltip>
      )}
    </Toolbar>
  );
};

export default function Contacts(): JSX.Element {
  const [ order, setOrder ] = React.useState<Order>( 'asc' );
  const [ orderBy, setOrderBy ] = React.useState<keyof Data>( 'alias' );
  const [ selected, setSelected ] = React.useState<readonly string[]>( [] );
  const [ page, setPage ] = React.useState( 0 );
  const [ dense, setDense ] = React.useState( false );
  const [ rows, setRows ] = React.useState<Data[]>( [] );
  const [ rowsPerPage, setRowsPerPage ] = React.useState( 10 );

  const [ contactDialogIsOpen, setContactDialogIsOpen ] = React.useState( false );
  const [ editId, setEditId ] = React.useState<number | null>( null );

  const refreshContacts = () => {
    ApiHelper.listContacts().then( ( result ) => {
      setSelected( [] );

      const tableData = result.data
        .map( ( contact ) => {
          if ( contact.attributes.contract_datum.data ) {
            return null;
          }
          if (
            contact.attributes.data[ 0 ].__component === 'contact.legal-entity'
          ) {
            return createData(
              contact.id.toString(),
              contact.attributes.alias,
              contact.attributes.data[ 0 ].company,
              '',
            );
          } else if (
            contact.attributes.data[ 0 ].__component === 'contact.natural-person'
          ) {
            return createData(
              contact.id.toString(),
              contact.attributes.alias,
              `${ contact.attributes.data[ 0 ].firstname } ${ contact.attributes.data[ 0 ].lastname }`,
              '',
            );
          }

          throw 'Error on fetching contact data';
        } )
        .filter( ( contact ) => contact !== null ) as Data[];

      setRows( tableData );
    } );
  };

  React.useEffect( () => {
    refreshContacts();
  }, [] );

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder( isAsc ? 'desc' : 'asc' );
    setOrderBy( property );
  };

  const handleSelectAllClick = ( event: React.ChangeEvent<HTMLInputElement> ) => {
    if ( event.target.checked ) {
      const newSelecteds = rows.map( ( n ) => n.key );
      setSelected( newSelecteds );
      return;
    }
    setSelected( [] );
  };

  const handleClick = ( event: React.MouseEvent<unknown>, key: string ) => {
    const selectedIndex = selected.indexOf( key );
    let newSelected: readonly string[] = [];

    if ( selectedIndex === -1 ) {
      newSelected = newSelected.concat( selected, key );
    } else if ( selectedIndex === 0 ) {
      newSelected = newSelected.concat( selected.slice( 1 ) );
    } else if ( selectedIndex === selected.length - 1 ) {
      newSelected = newSelected.concat( selected.slice( 0, -1 ) );
    } else if ( selectedIndex > 0 ) {
      newSelected = newSelected.concat(
        selected.slice( 0, selectedIndex ),
        selected.slice( selectedIndex + 1 ),
      );
    }

    setSelected( newSelected );
  };

  const handleChangePage = ( event: unknown, newPage: number ) => {
    setPage( newPage );
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage( parseInt( event.target.value, 10 ) );
    setPage( 0 );
  };

  const handleChangeDense = ( event: React.ChangeEvent<HTMLInputElement> ) => {
    setDense( event.target.checked );
  };

  const isSelected = ( key: string ) => selected.indexOf( key ) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max( 0, ( 1 + page ) * rowsPerPage - rows.length ) : 0;

  return (
    <>
      <ContactDialog
        contactDialogIsOpen={contactDialogIsOpen}
        setContactDialogIsOpen={setContactDialogIsOpen}
        refreshContacts={refreshContacts}
        setSelected={setSelected}
        editId={editId}
      />

      <Grid container spacing={2}>
        {/* <Grid item xs={12} sm={3}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <Typography variant="body1" component="div" sx={{ p: 1 }}>
            Gruppen
          </Typography>
        </Paper>
      </Grid> */}
        <Grid item xs={12} sm={12}>
          <Paper sx={{ width: '100%', mb: 2 }}>
            <EnhancedTableToolbar
              numSelected={selected.length}
              setContactDialogIsOpen={setContactDialogIsOpen}
            />
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
                  {stableSort( rows, getComparator( order, orderBy ) )
                    .slice( page * rowsPerPage, page * rowsPerPage + rowsPerPage )
                    .map( ( row, index ) => {
                      const isItemSelected = isSelected( row.key );
                      const labelId = `enhanced-table-checkbox-${ index }`;

                      return (
                        <TableRow
                          hover
                          onClick={( event ) => handleClick( event, row.key )}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.key}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId,
                              }}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {row.alias}
                          </TableCell>
                          <TableCell>{row.name}</TableCell>
                          <TableCell align="right">
                            <IconButton
                              onClick={() => {
                                setEditId( parseInt( row.key ) );
                                setContactDialogIsOpen( true );
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                ApiHelper.deleteContact( parseInt( row.key ) ).then(
                                  () => {
                                    refreshContacts();
                                  },
                                );
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    } )}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: ( dense ? 33 : 53 ) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[ 10, 25, 50 ]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
          <FormControlLabel
            control={<Switch checked={dense} onChange={handleChangeDense} />}
            label="Kompakte Ansicht"
          />
        </Grid>
      </Grid>
    </>
  );
}
