import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import ApiHelper from '../../common/ApiHelper';
import {
  ContactBase,
  ContactLegalEntity,
  ContactNaturalPerson,
  ContractPartner,
} from '../../common/ContractPartner';

export default function ContactDialog( props: {
  contactDialogIsOpen: boolean;
  setContactDialogIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  refreshContacts: () => void;
  setSelected?: React.Dispatch<React.SetStateAction<readonly string[]>>;
  editId: number | null;
} ): JSX.Element {
  const {
    contactDialogIsOpen,
    setContactDialogIsOpen,
    refreshContacts,
    setSelected,
    editId,
  } = props;

  const [ contactDialogId, setContactDialogId ] = React.useState( -1 );
  const [ contactDialogType, setContactDialogType ] = React.useState<
    ContactLegalEntity['__component'] | ContactNaturalPerson['__component']
  >( 'contact.legal-entity' );
  const [ contactDialogAlias, setContactDialogAlias ] = React.useState( '' );
  const [ contactDialogCompany, setContactDialogCompany ] = React.useState( '' );
  const [ contactDialogStreetNumber, setContactDialogStreetNumber ] =
    React.useState( '' );
  const [ contactDialogZipCity, setContactDialogZipCity ] = React.useState( '' );
  const [ contactDialogRepresentative, setContactDialogRepresentative ] =
    React.useState( '' );
  const [
    contactDialogFunctionRepresentative,
    setContactDialogFunctionRepresentative,
  ] = React.useState( '' );
  const [ contactDialogFirstname, setContactDialogFirstname ] =
    React.useState( '' );
  const [ contactDialogLastname, setContactDialogLastname ] = React.useState( '' );
  const [ contactDialogGender, setContactDialogGender ] =
    React.useState<ContactNaturalPerson['gender']>( 'male' );
  const [ contactDialogIsAdult, setContactDialogIsAdult ] = React.useState( true );

  const handleClose = () => {
    setContactDialogId( -1 );
    setContactDialogAlias( '' );
    setContactDialogCompany( '' );
    setContactDialogStreetNumber( '' );
    setContactDialogZipCity( '' );
    setContactDialogRepresentative( '' );
    setContactDialogFunctionRepresentative( '' );
    setContactDialogFirstname( '' );
    setContactDialogLastname( '' );
    setContactDialogGender( 'male' );
    setContactDialogIsAdult( true );
    setContactDialogIsOpen( false );
  };

  React.useEffect( () => {
    if ( editId ) {
      ApiHelper.getContact( editId ).then( ( result ) => {
        const contact = result.data.attributes.data[ 0 ];

        setContactDialogId( editId );
        setContactDialogAlias( result.data.attributes.alias );
        setContactDialogType( contact.__component );
        setContactDialogStreetNumber( contact.streetHouseNumber );
        setContactDialogZipCity( contact.zipCity );

        if ( contact.__component === 'contact.legal-entity' ) {
          setContactDialogCompany( contact.company );
          setContactDialogRepresentative( contact.representative );
          setContactDialogFunctionRepresentative(
            contact.functionRepresentative,
          );
        } else if ( contact.__component === 'contact.natural-person' ) {
          setContactDialogFirstname( contact.firstname );
          setContactDialogLastname( contact.lastname );
          setContactDialogGender( contact.gender );
          setContactDialogIsAdult( contact.isAdult );
        }

        if ( setSelected ) {
          setSelected( [] );
        }
      } );
    }
  }, [ contactDialogIsOpen ] );

  return (
    <Dialog
      open={contactDialogIsOpen}
      onClose={() => {
        handleClose();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Neuen Kontakt anlegen</DialogTitle>
      <DialogContent>
        <Grid container spacing={3} p={3}>
          <Grid item xs={12} lg={12}>
            <FormControl fullWidth>
              <InputLabel>Art des Kontakts</InputLabel>
              <Select
                label="Art des Kontakts"
                value={contactDialogType}
                onChange={( event ) =>
                  setContactDialogType(
                    event.target.value === 'contact.natural-person'
                      ? 'contact.natural-person'
                      : 'contact.legal-entity',
                  )
                }
              >
                <MenuItem value={'contact.legal-entity'}>
                  Juristische Person
                </MenuItem>
                <MenuItem value={'contact.natural-person'}>
                  Natürliche Person
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={12}>
            <TextField
              fullWidth
              label="Alias"
              placeholder="Musterküchen (CEO)"
              value={contactDialogAlias}
              onChange={( event ) => setContactDialogAlias( event.target.value )}
            />
          </Grid>
          {contactDialogType === 'contact.legal-entity' && (
            <Grid item xs={12} lg={12}>
              <TextField
                fullWidth
                label="Firmenname"
                placeholder="Musterküchen GmbH"
                value={contactDialogCompany}
                onChange={( event ) =>
                  setContactDialogCompany( event.target.value )
                }
              />
            </Grid>
          )}
          {contactDialogType === 'contact.natural-person' && (
            <Grid item xs={12} lg={12}>
              <TextField
                fullWidth
                label="Vorname"
                placeholder="Max"
                value={contactDialogFirstname}
                onChange={( event ) =>
                  setContactDialogFirstname( event.target.value )
                }
              />
            </Grid>
          )}
          {contactDialogType === 'contact.natural-person' && (
            <Grid item xs={12} lg={12}>
              <TextField
                fullWidth
                label="Nachname"
                placeholder="Musterküche"
                value={contactDialogLastname}
                onChange={( event ) =>
                  setContactDialogLastname( event.target.value )
                }
              />
            </Grid>
          )}
          <Grid item xs={12} lg={12}>
            <TextField
              fullWidth
              label="Straße und Hausnummer"
              placeholder="Großer Platz 7c"
              value={contactDialogStreetNumber}
              onChange={( event ) =>
                setContactDialogStreetNumber( event.target.value )
              }
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <TextField
              fullWidth
              label="PLZ und Stadt"
              placeholder="12345 Berlin"
              value={contactDialogZipCity}
              onChange={( event ) => setContactDialogZipCity( event.target.value )}
            />
          </Grid>
          {contactDialogType === 'contact.legal-entity' && (
            <Grid item xs={12} lg={12}>
              <TextField
                fullWidth
                label="Vertreter"
                placeholder="Max Musterküche"
                value={contactDialogRepresentative}
                onChange={( event ) =>
                  setContactDialogRepresentative( event.target.value )
                }
              />
            </Grid>
          )}
          {contactDialogType === 'contact.legal-entity' && (
            <Grid item xs={12} lg={12}>
              <TextField
                fullWidth
                label="Funktion des Vertreters"
                placeholder="Geschäftsführer"
                value={contactDialogFunctionRepresentative}
                onChange={( event ) =>
                  setContactDialogFunctionRepresentative( event.target.value )
                }
              />
            </Grid>
          )}
          {contactDialogType === 'contact.natural-person' && (
            <Grid item xs={12} lg={12}>
              <FormControl fullWidth>
                <InputLabel>Geschlecht</InputLabel>
                <Select
                  label="Geschlecht"
                  value={contactDialogGender}
                  onChange={( event ) => {
                    let gender: ContactNaturalPerson['gender'] = 'misc';

                    if ( event.target.value === 'male' ) {
                      gender = 'male';
                    } else if ( event.target.value === 'female' ) {
                      gender = 'female';
                    }

                    setContactDialogGender( gender );
                  }}
                >
                  <MenuItem value={'male'}>Männlich</MenuItem>
                  <MenuItem value={'female'}>Weiblich</MenuItem>
                  <MenuItem value={'misc'}>Sonstiges</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )}
          {contactDialogType === 'contact.natural-person' && (
            <Grid item xs={12} lg={12}>
              <FormControlLabel
                label="Volljährig"
                control={
                  <Switch
                    checked={contactDialogIsAdult}
                    onChange={( event ) =>
                      setContactDialogIsAdult( event.target.checked )
                    }
                  />
                }
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={false}
          onClick={() => {
            if ( contactDialogType === 'contact.legal-entity' ) {
              if ( contactDialogId < 0 ) {
                ApiHelper.addContact( contactDialogAlias, null, {
                  __component: 'contact.legal-entity',
                  company: contactDialogCompany,
                  streetHouseNumber: contactDialogStreetNumber,
                  zipCity: contactDialogZipCity,
                  representative: contactDialogRepresentative,
                  functionRepresentative: contactDialogFunctionRepresentative,
                } ).then( () => {
                  refreshContacts();
                } );
              } else {
                ApiHelper.updateContact( contactDialogId, contactDialogAlias, {
                  __component: 'contact.legal-entity',
                  company: contactDialogCompany,
                  streetHouseNumber: contactDialogStreetNumber,
                  zipCity: contactDialogZipCity,
                  representative: contactDialogRepresentative,
                  functionRepresentative: contactDialogFunctionRepresentative,
                } ).then( () => {
                  refreshContacts();
                } );
              }
            } else if ( contactDialogType === 'contact.natural-person' ) {
              if ( contactDialogId < 0 ) {
                ApiHelper.addContact( contactDialogAlias, null, {
                  __component: 'contact.natural-person',
                  firstname: contactDialogFirstname,
                  lastname: contactDialogLastname,
                  streetHouseNumber: contactDialogStreetNumber,
                  zipCity: contactDialogZipCity,
                  gender: contactDialogGender,
                  isAdult: contactDialogIsAdult,
                } ).then( () => {
                  refreshContacts();
                } );
              } else {
                ApiHelper.updateContact( contactDialogId, contactDialogAlias, {
                  __component: 'contact.natural-person',
                  firstname: contactDialogFirstname,
                  lastname: contactDialogLastname,
                  streetHouseNumber: contactDialogStreetNumber,
                  zipCity: contactDialogZipCity,
                  gender: contactDialogGender,
                  isAdult: contactDialogIsAdult,
                } ).then( () => {
                  refreshContacts();
                } );
              }
            }
            handleClose();
          }}
          autoFocus
        >
          Speichern
        </Button>
      </DialogActions>
    </Dialog>
  );
}
