import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, AnyAction } from 'redux';

import * as actionTypes from '../../../../store/actions';
import { ContractReduxState } from '../../../../store/reducer';
import { ContractDefinitionQuestionEnriched } from '../../../../common/ContractDefinitionEnriched';
import { ContractFormData } from '../../../../common/ContractData';
import SingleChoiceQuestion from './InputElements/SingleChoiceInput';
import MultipleChoiceQuestion from './InputElements/MultipleChoiceInput';
import TextAreaQuestion from './InputElements/TextAreaInput';
import TextFieldQuestion from './InputElements/TextFieldInput';
import CheckboxInput from './InputElements/CheckboxInput';
import SharesInput from './InputElements/SharesInput';
import NotificationHelper from '../../../../common/NotificationHelper';
import AttachmentInput from './InputElements/AttachmentInput';
import AttachmentReminderInput from './InputElements/AttachmentReminderInput';
import AttachmentRequirementInput from './InputElements/AttachmentRequirementInput';

export interface OwnProps {
  questionId: string;
  questionDefinition: ContractDefinitionQuestionEnriched;
  isInvalid: boolean;
}

interface StateProps {
  contractFormData: ContractFormData;
  showAllValidationErrors: boolean;
}

interface DispatchProps {
  onAnswersSet: ( questionId: string, answer: string | boolean ) => void;
  onAnswersSetRaw: (
    questionId: string,
    answerArray: string[] | boolean[],
  ) => void;
  onAnswerAdd: ( questionId: string, answer: string | boolean ) => void;
  onAnswerRemove: ( questionId: string, answer: string | boolean ) => void;
  onAnswersUnset: ( questionId: string ) => void;
}

type Props = OwnProps & StateProps & DispatchProps;

class QuestionInput extends React.Component<Props, {}> {
  valueChange(
    answer: string | string[] | boolean | boolean[],
    addValue: boolean | null = null,
    unsetWhenFalse = false,
  ): void {
    if ( typeof answer === 'string' ) {
      answer = answer.trimLeft();
    }

    if ( Array.isArray( answer ) ) {
      this.props.onAnswersSetRaw( this.props.questionId, answer );
    } else {
      if ( addValue === true ) {
        this.props.onAnswerAdd( this.props.questionId, answer );
      } else if ( addValue === false ) {
        this.props.onAnswerRemove( this.props.questionId, answer );
      } else if ( answer === '' || ( unsetWhenFalse && answer === false ) ) {
        this.props.onAnswersUnset( this.props.questionId );
      } else {
        this.props.onAnswersSet( this.props.questionId, answer );
      }
    }
  }

  render(): JSX.Element {
    const questionDefinition: ContractDefinitionQuestionEnriched =
      this.props.questionDefinition;

    let value: ( string | boolean )[] = [];
    if (
      this.props.contractFormData[ this.props.questionId ] !== undefined &&
      this.props.contractFormData[ this.props.questionId ].answers !== undefined
    ) {
      value = this.props.contractFormData[ this.props.questionId ].answers ?? [];
    }

    switch ( questionDefinition.__component ) {
    case 'question.none':
      return <></>;
    case 'question.single-choice':
      return (
        <SingleChoiceQuestion
          key={this.props.questionId}
          question={questionDefinition}
          name={this.props.questionId}
          value={value[ 0 ]}
          showValidationErrors={this.props.showAllValidationErrors}
          onValueChange={( answer: string ): void => this.valueChange( answer )}
        />
      );
    case 'question.multiple-choice':
      return (
        <MultipleChoiceQuestion
          key={this.props.questionId}
          question={questionDefinition}
          name={this.props.questionId}
          value={value}
          showValidationErrors={this.props.showAllValidationErrors}
          onValueChange={( answer: string | null, add: boolean ): void =>
            this.valueChange( answer ?? '', add )
          }
        />
      );
    case 'question.text-field':
      return (
        <TextFieldQuestion
          key={this.props.questionId}
          type="plain"
          question={questionDefinition}
          value={value[ 0 ] as string}
          showValidationErrors={this.props.showAllValidationErrors}
          isInvalid={this.props.isInvalid}
          onValueChange={( answer: string ): void => this.valueChange( answer )}
        />
      );
      /*case 'currencyField':
      return (
        <TextFieldQuestion
          key={this.props.questionId}
          type="currency"
          question={questionDefinition}
          value={value[ 0 ] as string}
          showValidationErrors={this.props.showAllValidationErrors}
          isInvalid={this.props.isInvalid}
          onValueChange={( answer: string ): void => this.valueChange( answer )}
        />
      );
    case 'percentageField':
      return (
        <TextFieldQuestion
          key={this.props.questionId}
          type="percentage"
          question={questionDefinition}
          value={value[ 0 ] as string}
          showValidationErrors={this.props.showAllValidationErrors}
          isInvalid={this.props.isInvalid}
          onValueChange={( answer: string ): void => this.valueChange( answer )}
        />
      );*/
    case 'question.text-area':
      return (
        <TextAreaQuestion
          key={this.props.questionId}
          question={questionDefinition}
          value={value[ 0 ] as string}
          showValidationErrors={this.props.showAllValidationErrors}
          isInvalid={this.props.isInvalid}
          onValueChange={( answer: string ): void => this.valueChange( answer )}
        />
      );
    case 'question.checkbox':
      return (
        <CheckboxInput
          key={this.props.questionId}
          question={questionDefinition}
          value={value[ 0 ]}
          showValidationErrors={this.props.showAllValidationErrors}
          onValueChange={( answer: boolean ): void => this.valueChange( answer )}
        />
      );
    case 'question.shares':
      return (
        <SharesInput
          key={this.props.questionId}
          question={questionDefinition}
          value={value}
          showValidationErrors={this.props.showAllValidationErrors}
          onValueChange={( answer: string[] ): void => this.valueChange( answer )}
          contractFormData={this.props.contractFormData}
        />
      );
    case 'question.attachment':
      return (
        <AttachmentInput
          key={this.props.questionId}
          question={questionDefinition}
          value={value[ 0 ]}
          showValidationErrors={this.props.showAllValidationErrors}
          onValueChange={( answer: string ): void => this.valueChange( answer )}
          contractFormData={this.props.contractFormData}
        />
      );
    case 'question.attachment-reminder':
      return (
        <AttachmentReminderInput
          key={this.props.questionId}
          question={questionDefinition}
          name={this.props.questionId}
          value={value[ 0 ]}
          showValidationErrors={this.props.showAllValidationErrors}
          onValueChange={( answer: string ): void => this.valueChange( answer )}
        />
      );
    case 'question.attachment-requirement':
      return (
        <AttachmentRequirementInput
          key={this.props.questionId}
          question={questionDefinition}
          value={value[ 0 ]}
          showValidationErrors={this.props.showAllValidationErrors}
          onValueChange={( answer: boolean ): void =>
            this.valueChange( answer, null, true )
          }
        />
      );
    default:
      NotificationHelper.silentError(
        'Unknown question type in QuestionInput',
        questionDefinition,
      );
      return <></>;
    }
  }
}

const mapStateToProps = ( state: ContractReduxState ): StateProps => {
  return {
    contractFormData: state.contractFormData,
    showAllValidationErrors: state.showAllValidationErrors,
  };
};

const mapDispatchToProps = ( dispatch: Dispatch ): DispatchProps => {
  return {
    onAnswersSet: ( questionId: string, answer: string | boolean ): AnyAction =>
      dispatch( {
        type: actionTypes.SET_ANSWERS,
        questionId: questionId,
        answers: [ answer ],
      } ),
    onAnswersSetRaw: (
      questionId: string,
      answerArray: string[] | boolean[],
    ): AnyAction =>
      dispatch( {
        type: actionTypes.SET_ANSWERS,
        questionId: questionId,
        answers: answerArray,
      } ),
    onAnswerAdd: ( questionId: string, answer: string | boolean ): AnyAction =>
      dispatch( {
        type: actionTypes.ADD_ANSWER,
        questionId: questionId,
        answer: answer,
      } ),
    onAnswerRemove: ( questionId: string, answer: string | boolean ): AnyAction =>
      dispatch( {
        type: actionTypes.REMOVE_ANSWER,
        questionId: questionId,
        answer: answer,
      } ),
    onAnswersUnset: ( questionId: string ): AnyAction =>
      dispatch( {
        type: actionTypes.UNSET_ANSWERS,
        questionId: questionId,
      } ),
  };
};

export default connect( mapStateToProps, mapDispatchToProps )( QuestionInput );
