import * as actionTypes from './actions';
import { Reducer, AnyAction } from 'redux';

import {
  ContractDefinitionEnriched,
  ContractDefinitionQuestionSetEnriched,
} from '../common/ContractDefinitionEnriched';
import { ContractFormData, ContractVariableData } from '../common/ContractData';
import LocalStorageHelper from '../common/LocalStorageHelper';
import evaluateConditions from '../common/evaluateConditions';
import { InfoDrawerContent } from '../common/InfoDrawerContent';
import PlaceholderHelper from '../common/PlaceholderHelper';
import ValidationHelper from '../common/ValidationHelper';
import NotificationHelper from '../common/NotificationHelper';
import { ContractPartner } from '../common/ContractPartner';
import { ContractDefinitionVariable } from '../common/ContractDefinition';
import StringHelper from '../common/StringHelper';

export interface ContractReduxState {
  contractDefinition: ContractDefinitionEnriched;
  contractShortNote: string;
  contractLongNote: string;
  contractFirstParty: ContractPartner;
  contractSecondParty: ContractPartner;
  contractThirdParties: ContractPartner[];
  contractFormData: ContractFormData;
  contractFormDataSaved: ContractFormData;
  contractFormDataSavedId: number;
  contractVariableData: ContractVariableData;
  showAllValidationErrors: boolean;
  invalidQuestionData: string[];
  hiddenQuestions: string[];
  firstPageWithError: number | null;
  infoDrawerContent: InfoDrawerContent;
  infoDrawerState: { isOpen: boolean };
}

const initialState: ContractReduxState = {
  contractDefinition: {
    id: 0,
    attributes: {
      title: '',
      titlePlural: '',
      shortDescription: '',
    },
    questions: [],
    variables: {},
    paragraphs: {},
  },
  contractShortNote: '',
  contractLongNote: '',
  contractFirstParty: {
    id: 0,
    alias: '',
    contract_datum: { data: null },
    data: [
      {
        __component: 'contact.legal-entity',
        company: '',
        streetHouseNumber: '',
        zipCity: '',
        representative: '',
        functionRepresentative: '',
      },
    ],
  },
  contractSecondParty: {
    id: 0,
    alias: '',
    contract_datum: { data: null },
    data: [
      {
        __component: 'contact.legal-entity',
        company: '',
        streetHouseNumber: '',
        zipCity: '',
        representative: '',
        functionRepresentative: '',
      },
    ],
  },
  contractThirdParties: [],
  contractFormData: {},
  contractFormDataSaved: {},
  contractFormDataSavedId: 0,
  contractVariableData: {},
  invalidQuestionData: [],
  hiddenQuestions: [],
  firstPageWithError: null,
  showAllValidationErrors: false,
  infoDrawerContent: {
    headline: 'Hilfe und Erklärungen',
    text:
      'Klicke auf das Infosymbol neben einer Frage, um Hilfe und weitere ' +
      'Erklärungen zu erhalten.',
  },
  infoDrawerState: {
    isOpen: false,
  },
};

const saveToLocalStorage = ( state: ContractReduxState ): void => {
  try {
    if (
      state.contractDefinition !== undefined &&
      state.contractDefinition !== null
    ) {
      LocalStorageHelper.set(
        state.contractFormDataSavedId.toString(),
        JSON.stringify( state.contractFormData ),
      );
    }
  } catch {}
};

const loadFromLocalStorage = ( action: AnyAction ): {} => {
  try {
    if (
      action.contractDefinition !== undefined &&
      action.contractDefinition !== null
    ) {
      const parsedJson = JSON.parse( LocalStorageHelper.get( action.id ) );

      if ( parsedJson !== null ) {
        return parsedJson;
      }
    }
  } catch ( error ) {
    NotificationHelper.silentError( error );
  }

  return {};
};

const refreshVariables = ( state: ContractReduxState ): ContractVariableData => {
  if ( state.contractDefinition === null ) {
    return {};
  }

  const contractVariableData: ContractVariableData = {};

  Object.entries( state.contractDefinition.variables ).forEach( ( [ key, value ] ) => {
    switch ( value.__component ) {
    case 'variable.boolean-switch':
      contractVariableData[ key ] = {
        type: 'variable',
        answers: [
          evaluateConditions( value.conditions, {
            ...state.contractFormData,
            ...state.contractVariableData,
          } ),
        ],
      };
      break;
    case 'variable.text':
      const findResult = Object.entries( value.data ).find(
        ( [ dataKey, dataValue ] ) => {
          const evaluationResult = evaluateConditions( dataValue.conditions, {
            ...state.contractFormData,
            ...state.contractVariableData,
          } );

          if ( evaluationResult ) {
            contractVariableData[ key ] = {
              type: 'variable',
              answers: [ dataKey ],
            };
          }

          return evaluationResult;
        },
      );

      // no condition did fit, so we at least set a defined state
      if ( findResult === undefined ) {
        contractVariableData[ key ] = { type: 'variable', answers: null };
      }
      break;
    case 'variable.addition':
      contractVariableData[ key ] = {
        type: 'variable',
        answers: [
          Object.values( value.data )
            .reduce( ( accumulator: number, termId ): number => {
              let additive = 0;
              if ( termId.startsWith( 'q_' ) ) {
                const questionValue = contractVariableData[ termId ];
                if ( questionValue !== undefined ) {
                  additive = parseInt( questionValue.toString() );
                }
              } else if ( termId.startsWith( 'v_' ) ) {
                const variableValue = state.contractFormData[ termId ];
                if ( variableValue !== undefined ) {
                  additive = parseInt( variableValue.toString() );
                }
              }
              return accumulator + additive;
            }, 0 )
            .toString(),
        ],
      };
      break;
    default:
      break;
    }
  } );

  contractVariableData[ 'v_currentDate' ] = {
    type: 'variable',
    answers: [ new Date().toLocaleDateString( 'de' ) ],
  };

  return contractVariableData;
};

/**
 * Iterates through every question. If the conditions of the question are
 * matched, the form data for the question will be validated. For the first
 * error, the page index will be saved. All validation errors are collected
 * in an array and returned with the page index.
 *
 * @param state the upcoming redux state
 * @returns an array containing an array of invalid question ids and a page
 *  index on where the first invalid question has been found
 */
const refreshValidation = (
  state: ContractReduxState,
): [string[], number | null] => {
  const invalidQuestionData: string[] = [];
  let firstPageWithError: number | null = null;

  if ( !state.contractDefinition ) {
    return [ [], null ];
  }

  state.contractDefinition.questions.forEach( ( questionSet ) => {
    Object.entries( questionSet ).forEach( ( [ questionId, question ] ) => {
      // needed for shares and maybe other types where correct data
      // arrays/objects are important for validation
      PlaceholderHelper.assembleQuestionTexts(
        question,
        state.contractDefinition,
        state.contractFormData,
        state.contractVariableData,
      );

      const conditionResult = evaluateConditions( question.conditions, {
        ...state.contractFormData,
        ...state.contractVariableData,
      } );
      if ( conditionResult ) {
        const validationResult = ValidationHelper.validateQuestion(
          question,
          state.contractFormData[ questionId ],
        );
        if ( validationResult !== true ) {
          invalidQuestionData.push( questionId );
          if (
            firstPageWithError === null ||
            firstPageWithError > ( question.pageIndex ?? 0 )
          ) {
            firstPageWithError = question.pageIndex ?? 0;
          }
        }
      }
    } );
  } );

  return [ invalidQuestionData, firstPageWithError ];
};

const unsetAnswers = ( state: ContractReduxState, questionId: string ) => {
  let newState = {
    ...state,
    contractFormData: {
      ...state.contractFormData,
    },
  };
  delete newState.contractFormData[ questionId ];

  newState.contractVariableData = refreshVariables( newState );
  [ newState.invalidQuestionData, newState.firstPageWithError ] =
    refreshValidation( newState );
  newState = refreshHiddenQuestions( newState );

  return newState;
};

const refreshHiddenQuestions = ( state: ContractReduxState ) => {
  const BreakException = {};
  let newState = { ...state, hiddenQuestions: [ ...state.hiddenQuestions ] };

  try {
    newState.contractDefinition.questions.forEach( ( QuestionSet ) => {
      Object.entries( QuestionSet ).forEach(
        ( [ questionId, questionDefinition ] ) => {
          let showQuestion = true;

          const evaluationResult = evaluateConditions(
            questionDefinition.conditions,
            {
              ...newState.contractFormData,
              ...newState.contractVariableData,
            },
          );

          if ( !evaluationResult ) {
            if ( newState.contractFormData[ questionId ] !== undefined ) {
              newState = unsetAnswers( state, questionId );
              throw BreakException;
            }

            showQuestion = false;
          }

          if (
            PlaceholderHelper.isAnyPlaceholderEmpty(
              [
                ...questionDefinition.questionTextPlaceholderList,
                ...questionDefinition.explanationPlaceholderList,
                ...questionDefinition.dataPlaceholderList,
              ],
              newState.contractFormData,
              newState.contractVariableData,
            )
          ) {
            showQuestion = false;
          }

          if ( showQuestion ) {
            newState.hiddenQuestions = newState.hiddenQuestions.filter(
              ( element: string ) => element !== questionId,
            );
          } else {
            if ( newState.hiddenQuestions.indexOf( questionId ) === -1 ) {
              newState.hiddenQuestions.push( questionId );
            }
          }
        },
      );
    } );
  } catch ( e ) {
    if ( e !== BreakException ) throw e;
  }

  return newState;
};

const reducer: Reducer = ( state = initialState, action ) => {
  switch ( action.type ) {
  case actionTypes.FETCH_CONTRACT: {
    const questionPages: {
        [id: string]: {
          __component: string;
          name: string;
          type: string;
          data: { [id: string]: {} };
          choices: [{ name: string }];
          active?: boolean;
        };
      }[] = [ {} ];
    action.contractDefinition.contractModules.forEach(
      (
        contractModule: {
            contract_module: {
              questions: [
                {
                  __component: string;
                  name: string;
                  type: string;
                  data: { [id: string]: {} };
                  choices: [{ name: string }];
                  active?: boolean;
                },
              ];
            };
          },
        index: number,
      ) => {
        contractModule.contract_module.questions.forEach( ( question ) => {
          if ( question.__component !== 'misc.break-point' ) {
            questionPages[ questionPages.length - 1 ][
              StringHelper.toSlug( question.name )
            ] = question;
          } else {
            if ( question.active === true ) {
              questionPages.push( {} );
            }
          }
        } );

        if ( index < action.contractDefinition.contractModules.length - 1 ) {
          questionPages.push( {} );
        }
      },
    );

    action.contractDefinition.questions = questionPages;

    const variables: { [id: string]: { name: string } } = {};
    action.contractDefinition.contractModules.forEach(
      ( contractModule: {
          contract_module: {
            variables: [
              {
                __component: string;
                name: string;
              },
            ];
          };
        } ) => {
        contractModule.contract_module.variables.forEach( ( variable ) => {
          variables[ variable.name ] = variable;
        } );
      },
    );

    action.contractDefinition.variables = variables;

    // add page indices to every question definition
    action.contractDefinition.questions.forEach(
      ( questionSet: ContractDefinitionQuestionSetEnriched, index: number ) => {
        Object.values( questionSet ).forEach( ( question ) => {
          question.pageIndex = index;
        } );
      },
    );

    // set all variables to a defined state
    Object.entries(
        action.contractDefinition.variables as ContractDefinitionVariable[],
    ).forEach( ( [ key, value ]: [string, ContractDefinitionVariable] ) => {
      if ( value.__component === 'variable.boolean-switch' ) {
        state.contractVariableData[ key ] = { answer: false };
      } else {
        state.contractVariableData[ key ] = { answer: null };
      }
    } );

    PlaceholderHelper.enrichContractDefinitionWithPlaceholders(
      action.contractDefinition,
    );

    let contractFormData = loadFromLocalStorage( action );
    if ( !contractFormData || Object.keys( contractFormData ).length === 0 ) {
      contractFormData = action.contractFormData;
    }

    let newState = {
      ...state,
      contractShortNote: action.shortNote,
      contractLongNote: action.longNote,
      contractFirstParty: action.firstParty,
      contractSecondParty: action.secondParty,
      contractThirdParties: action.thirdParties,
      contractDefinition: action.contractDefinition,
      contractFormData,
      contractFormDataSaved: action.contractFormData,
      contractFormDataSavedId: action.id,
    };

    newState.contractVariableData = refreshVariables( newState );
    [ newState.invalidQuestionData, newState.firstPageWithError ] =
        refreshValidation( newState );
    newState = refreshHiddenQuestions( newState );

    return newState;
  }
  case actionTypes.SET_CONTRACTFORMDATASAVED: {
    return {
      ...state,
      contractFormDataSaved: {
        ...action.contractFormData,
        ...action.contractVariableData,
      },
    };
  }
  case actionTypes.SET_ANSWERS: {
    let newState = {
      ...state,
      contractFormData: {
        ...state.contractFormData,
        [ action.questionId ]: {
          ...state.contractFormData[ action.questionId ],
          answers: action.answers,
        },
      },
    };

    newState.contractVariableData = refreshVariables( newState );
    [ newState.invalidQuestionData, newState.firstPageWithError ] =
        refreshValidation( newState );
    newState = refreshHiddenQuestions( newState );

    saveToLocalStorage( newState );

    return newState;
  }
  case actionTypes.ADD_ANSWER: {
    let newState = {
      ...state,
      contractFormData: {
        ...state.contractFormData,
        [ action.questionId ]: {
          ...state.contractFormData[ action.questionId ],
        },
      },
    };

    if (
      !Array.isArray( newState.contractFormData[ action.questionId ].answers )
    ) {
      newState.contractFormData[ action.questionId ].answers = [];
    } else {
      newState.contractFormData[ action.questionId ].answers = [
        ...state.contractFormData[ action.questionId ].answers,
      ];
    }

    if (
      !newState.contractFormData[ action.questionId ].answers.includes(
        action.answer,
      )
    ) {
      newState.contractFormData[ action.questionId ].answers.push(
        action.answer,
      );
    }

    newState.contractVariableData = refreshVariables( newState );
    [ newState.invalidQuestionData, newState.firstPageWithError ] =
        refreshValidation( newState );
    newState = refreshHiddenQuestions( newState );

    saveToLocalStorage( newState );

    return newState;
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  case actionTypes.REMOVE_ANSWER: {
    if (
      Array.isArray( state.contractFormData[ action.questionId ]?.answers ) &&
        state.contractFormData[ action.questionId ].answers.length > 1
    ) {
      let newState = {
        ...state,
        contractFormData: {
          ...state.contractFormData,
          [ action.questionId ]: {
            ...state.contractFormData[ action.questionId ],
          },
        },
      };

      if (
        !Array.isArray( state.contractFormData[ action.questionId ]?.answers )
      ) {
        newState.contractFormData[ action.questionId ].answers = [];
      } else {
        newState.contractFormData[ action.questionId ].answers =
            state.contractFormData[ action.questionId ].answers.filter(
              ( element: string | boolean ) => element !== action.answer,
            );
      }

      newState.contractVariableData = refreshVariables( newState );
      [ newState.invalidQuestionData, newState.firstPageWithError ] =
          refreshValidation( newState );
      newState = refreshHiddenQuestions( newState );

      saveToLocalStorage( newState );

      return newState;
    }
    // else: 'answers' is not an array or will end up empty, so we will continue
    // by un-setting the whole 'answers'-object.
    // Therefore no break or return here.
    /* falls through */
  }
  case actionTypes.UNSET_ANSWERS: {
    const newState = unsetAnswers( state, action.questionId );

    saveToLocalStorage( newState );

    return newState;
  }
  case actionTypes.UNSET_ALL_ANSWERS: {
    let newState = {
      ...state,
      contractFormData: {},
    };

    newState.contractVariableData = refreshVariables( newState );
    [ newState.invalidQuestionData, newState.firstPageWithError ] =
        refreshValidation( newState );
    newState = refreshHiddenQuestions( newState );

    saveToLocalStorage( newState );

    return newState;
  }
  case actionTypes.SET_INFO_DRAWER_CONTENT:
    return {
      ...state,
      infoDrawerContent: {
        ...state.infoDrawerContent,
        headline: action.headline,
        text: action.text,
      },
    };
  case actionTypes.SET_INFO_DRAWER_STATE:
    return {
      ...state,
      infoDrawerState: {
        ...state.infoDrawerState,
        isOpen: action.isOpen,
      },
    };
  case actionTypes.SHOW_ALL_VALIDATION_ERRORS:
    return {
      ...state,
      showAllValidationErrors: true,
    };
  case actionTypes.SET_FIRST_PARTY_CONTACT:
    return {
      ...state,
      contractFirstParty: action.contractFirstParty,
    };
  case actionTypes.SET_SECOND_PARTY_CONTACT:
    return {
      ...state,
      contractSecondParty: action.contractSecondParty,
    };

  case actionTypes.SET_THIRD_PARTY_CONTACTS:
    return {
      ...state,
      contractThirdParties: action.contractThirdParties,
    };
  default:
    return state;
  }
};

export default reducer;
