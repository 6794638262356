import React from 'react';

import './theme.css';

import { createTheme, ThemeProvider, useTheme } from '@mui/material';
import { deDE } from '@mui/material/locale';

export const ColorContext = React.createContext( {
  toggleColorMode: () => {
    //
  },
  changeCustomColors: ( colors: { primary: { main: string } } ) => {
    //
  },
} );

export function Theme( { children }: { children: JSX.Element } ): JSX.Element {
  const [ mode, setMode ] = React.useState<'light' | 'dark'>( 'light' );
  const [ customColors, setCustomColors ] = React.useState( {
    primary: { main: '#902c7c' },
  } );
  const colorMode = React.useMemo(
    () => ( {
      toggleColorMode: () => {
        setMode( ( prevMode ) => ( prevMode === 'light' ? 'dark' : 'light' ) );
      },
      changeCustomColors: ( colors: { primary: { main: string } } ) => {
        setCustomColors( colors );
      },
    } ),
    [],
  );

  const theme = React.useMemo(
    () =>
      createTheme(
        {
          typography: {
            fontFamily: 'Montserrat',
          },
          palette: {
            mode,
            primary: {
              main: customColors.primary.main,
            },
          },
        },
        deDE,
      ),
    [ mode, customColors ],
  );

  return (
    <ColorContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ColorContext.Provider>
  );
}
