import React from 'react';
import {
  Card,
  CardActions,
  CardContent,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

export default function NewContractCard( props: {
  title: string;
  shortDescription?: string | JSX.Element;
  handleNew: () => void;
} ): JSX.Element {
  const { title, shortDescription, handleNew } = props;

  return (
    <Card>
      <CardContent>
        <Typography
          variant="h6"
          component="div"
          color="primary"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {title}
        </Typography>
        <Divider />
        <Typography
          variant="body2"
          sx={{
            overflow: 'hidden',
            minHeight: '6.5em',
          }}
        >
          {shortDescription}
        </Typography>
      </CardContent>
      <CardActions>
        <IconButton onClick={handleNew}>
          <AddIcon />
        </IconButton>
      </CardActions>
    </Card>
  );
}
