import { Checkbox, FormControl, FormControlLabel } from '@mui/material';
import * as React from 'react';

import { ContractDefinitionAttachmentRequirementQuestion } from '../../../../../common/ContractDefinition';

export interface OwnProps {
  question: ContractDefinitionAttachmentRequirementQuestion;
  value: string | boolean;
  showValidationErrors: boolean;
  onValueChange: ( answer: boolean ) => void;
}

class AttachmentRequirementInput extends React.Component<
  OwnProps,
  {
    showValidationErrors: boolean;
  }
> {
  constructor( props: OwnProps ) {
    super( props );
    this.state = { showValidationErrors: false };
  }

  onValueChange( event: React.SyntheticEvent ): void {
    this.props.onValueChange( ( event.target as HTMLInputElement ).checked );
  }

  enableValidationErrors(): void {
    this.setState( { ...this.state, showValidationErrors: true } );
  }

  render(): JSX.Element {
    let checked = false;
    if ( this.props.value === true ) {
      checked = true;
    }

    const classes: string[] = [ 'clarius-gen-answer-block' ];
    if ( this.props.showValidationErrors || this.state.showValidationErrors ) {
      classes.push( 'clarius-gen-show-errors' );
    }

    return (
      <FormControl className={classes.join( ' ' )}>
        <FormControlLabel
          onChange={( e ): void => this.onValueChange( e )}
          onBlur={(): void => this.enableValidationErrors()}
          checked={checked}
          control={<Checkbox />}
          label="Das Dokument liegt vor"
        />
      </FormControl>
    );
  }
}

export default AttachmentRequirementInput;
