import React from 'react';
import {
  Card,
  CardActions,
  CardContent,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';

import { useNavigate } from 'react-router-dom';
import downloadContract from '../../common/downloadContract';
import LocalStorageHelper from '../../common/LocalStorageHelper';
import { ContractPartner } from '../../common/ContractPartner';
import { create } from 'domain';
import DateHelper from '../../common/DateHelper';

export default function ComplyChainCard( props: {
  contractDataId: number;
  title: string;
  shortDescription?: string | JSX.Element;
  createdAt: string;
  updatedAt: string;
  handleClose?: () => void;
} ): JSX.Element {
  const {
    contractDataId,
    title,
    shortDescription,
    createdAt,
    updatedAt,
    handleClose,
  } = props;

  const navigate = useNavigate();

  function handleEdit() {
    navigate( `/contract/${ contractDataId }` );
  }

  return (
    <Card>
      <CardContent>
        <Typography
          variant="h6"
          component="div"
          color="primary"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {title}
        </Typography>
        <Divider />
        <Typography
          variant="body2"
          sx={{
            overflow: 'hidden',
            minHeight: '6.5em',
          }}
        >
          {shortDescription}
        </Typography>
        <br />
        <Typography variant="subtitle2">
          <AddIcon fontSize="inherit" /> {DateHelper.simpleDate( createdAt )}
          <br />
          <EditIcon fontSize="inherit" /> {DateHelper.simpleDate( updatedAt )}
        </Typography>
      </CardContent>
      <CardActions>
        {handleClose ? (
          <>
            <IconButton onClick={handleEdit}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={handleClose}>
              <DoneIcon />
            </IconButton>
          </>
        ) : (
          <></>
        )}
      </CardActions>
    </Card>
  );
}
