import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, AnyAction } from 'redux';

import * as actionTypes from '../../../store/actions';
import { ContractReduxState } from '../../../store/reducer';
import {
  ContractFormData,
  ContractVariableData,
} from '../../../common/ContractData';
import evaluateConditions from '../../../common/evaluateConditions';
import {
  ContractDefinitionQuestionEnriched,
  ContractDefinitionEnriched,
} from '../../../common/ContractDefinitionEnriched';
import PlaceholderHelper from '../../../common/PlaceholderHelper';
import { Box, Grid, IconButton } from '@mui/material';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';

export interface OwnProps {
  questionId: string;
  questionDefinition: ContractDefinitionQuestionEnriched;
  onReviewQuestion: () => void;
  onlyIfChanged?: boolean;
}

interface StateProps {
  contractDefinition: ContractDefinitionEnriched;
  contractFormData: ContractFormData;
  contractFormDataSaved: ContractFormData;
  contractVariableData: ContractVariableData;
}

interface DispatchProps {
  onAnswerUnset: ( questionId: string ) => void;
}

type Props = StateProps & DispatchProps & OwnProps;

class QuestionReview extends React.Component<Props, {}> {
  render(): JSX.Element {
    const evaluationResult = evaluateConditions(
      this.props.questionDefinition.conditions,
      { ...this.props.contractFormData, ...this.props.contractVariableData },
    );

    if ( !evaluationResult ) {
      if ( this.props.contractFormData[ this.props.questionId ] !== undefined ) {
        this.props.onAnswerUnset( this.props.questionId );
      }

      return <></>;
    }

    if (
      PlaceholderHelper.isAnyPlaceholderEmpty(
        [
          ...this.props.questionDefinition.questionTextPlaceholderList,
          ...this.props.questionDefinition.explanationPlaceholderList,
          ...this.props.questionDefinition.dataPlaceholderList,
        ],
        this.props.contractFormData,
        this.props.contractVariableData,
      )
    ) {
      return <></>;
    }

    const questionDefinition = PlaceholderHelper.assembleQuestionTexts(
      this.props.questionDefinition,
      this.props.contractDefinition,
      this.props.contractFormData,
      this.props.contractVariableData,
    );

    let answers: string | JSX.Element[] = 'Keine Angabe';
    let answersSaved: string | JSX.Element[] = 'Keine Angabe';
    if (
      this.props.contractFormData[ this.props.questionId ] !== undefined &&
      this.props.contractFormData[ this.props.questionId ].answers !== undefined
    ) {
      if ( questionDefinition.__component === 'question.single-choice' ) {
        answers =
          questionDefinition.choices.find(
            ( choice ) =>
              choice.name ===
              this.props.contractFormData[
                this.props.questionId
              ]?.answers?.[ 0 ].toString(),
          )?.shortAnswer ?? '';

        answersSaved =
          questionDefinition.choices.find(
            ( choice ) =>
              choice.name ===
              this.props.contractFormDataSaved[
                this.props.questionId
              ]?.answers?.[ 0 ].toString(),
          )?.shortAnswer ?? '';
      } else if (
        questionDefinition.__component === 'question.multiple-choice'
      ) {
        answers = '';

        this.props.contractFormData[
          this.props.questionId ?? 0
        ].answers?.forEach( ( answer ) => {
          answers +=
            ( questionDefinition.choices.find(
              ( choice ) =>
                choice.name ===
                this.props.contractFormData[
                  this.props.questionId
                ].answers?.[ 0 ].toString(),
            )?.shortAnswer ?? '' ) + '\n';
        } );

        answersSaved = '';

        this.props.contractFormDataSaved[
          this.props.questionId ?? 0
        ]?.answers?.forEach( ( answer ) => {
          answersSaved +=
            ( questionDefinition.choices.find(
              ( choice ) =>
                choice.name ===
                this.props.contractFormData[
                  this.props.questionId
                ].answers?.[ 0 ].toString(),
            )?.shortAnswer ?? '' ) + '\n';
        } );
      } else {
        answers =
          this.props.contractFormData[
            this.props.questionId
          ]?.answers?.[ 0 ].toString() ?? '';

        answersSaved =
          this.props.contractFormDataSaved[
            this.props.questionId
          ]?.answers?.[ 0 ].toString() ?? '';
      }
    }

    const answerRows = answers.split( '\n' );
    const answersJsxElements = answerRows.map( ( text, i ) => {
      return <p key={i}>{text}</p>;
    } );

    const answerSavedRows = answersSaved.split( '\n' );
    const answersSavedJsxElements = answerSavedRows.map( ( text, i ) => {
      return <p key={i}>{text}</p>;
    } );

    if ( this.props.onlyIfChanged && answers === answersSaved ) {
      return <></>;
    }

    return (
      <>
        <p>{questionDefinition.questionText}</p>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            {answers === answersSaved || (
              <Box color={( theme ) => theme.palette.grey[ 500 ]}>
                {answersSavedJsxElements}
              </Box>
            )}
            <Box>{answersJsxElements}</Box>
          </Grid>
          <Grid item>
            <IconButton
              color="primary"
              component="span"
              onClick={this.props.onReviewQuestion}
            >
              <HighlightAltIcon />
            </IconButton>
          </Grid>
        </Grid>

        <hr />
      </>
    );
  }
}

const mapStateToProps = ( state: ContractReduxState ): StateProps => {
  return {
    contractDefinition: state.contractDefinition,
    contractFormData: state.contractFormData,
    contractFormDataSaved: state.contractFormDataSaved,
    contractVariableData: state.contractVariableData,
  };
};

const mapDispatchToProps = ( dispatch: Dispatch ): DispatchProps => {
  return {
    onAnswerUnset: ( questionId: string ): AnyAction =>
      dispatch( {
        type: actionTypes.UNSET_ANSWERS,
        questionId: questionId,
      } ),
  };
};

export default connect( mapStateToProps, mapDispatchToProps )( QuestionReview );
