import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionSummary,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import OpenContractCard from './openContractCard';
import NewContractCard from './newContractCard';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ClosedContractCard from './closedContractCard';
import { useNavigate } from 'react-router-dom';
import ApiHelper, { ContractData } from '../../common/ApiHelper';
import { ContractDefinition } from '../../common/ContractDefinition';
import DateHelper from '../../common/DateHelper';
import ComplyChainCard from './complyChainCard';

export default function Dashboard() {
  const [ contractDefinitions, setContractDefinitions ] = useState<
    ContractDefinition[] | null
  >();
  const [ contracts, setContracts ] = useState<{
    closedContracts: ContractData[];
    openContracts: ContractData[];
  } | null>();

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const fetchContracts = () => {
    ApiHelper.listContractDefinitions().then( ( response ) => {
      setContractDefinitions( response.data );
    } );

    ApiHelper.listContractDatas().then( ( response ) => {
      const closedContracts: ContractData[] = [];
      const openContracts: ContractData[] = [];
      response.data.forEach( ( contractData ) => {
        if ( contractData.attributes.closed ) {
          closedContracts.push( contractData );
        } else {
          openContracts.push( contractData );
        }
      } );
      setContracts( { closedContracts, openContracts } );
    } );
  };

  useEffect( () => {
    fetchContracts();
  }, [] );

  function handleNew( contractDefinitiontId: number ) {
    ApiHelper.createContractData( contractDefinitiontId ).then( ( response ) => {
      navigate( `/contract/${ response.data.id }` );
    } );
  }

  function handleClose( contractDataId: number ): void {
    ApiHelper.closeContractData( contractDataId ).then( () => {
      fetchContracts();
    } );
  }

  const availableContractDefinitions: JSX.Element[] = [];

  if ( contractDefinitions ) {
    contractDefinitions.forEach( ( availableContractDefinition ) => {
      availableContractDefinitions.push(
        <Grid item xs={12} sm={6} lg={3} key={availableContractDefinition.id}>
          <NewContractCard
            title={availableContractDefinition.attributes.title}
            shortDescription={
              availableContractDefinition.attributes.shortDescription
            }
            handleNew={() => handleNew( availableContractDefinition.id )}
          />
        </Grid>,
      );
    } );
  }

  let openContractCounter = 0;
  const openContractsJSX: JSX.Element[] = [];

  if ( contracts ) {
    Object.entries( contracts.openContracts ).forEach( ( [ key, contract ] ) => {
      openContractCounter++;

      openContractsJSX.push(
        <Grid item xs={12} sm={6} lg={3} key={key}>
          <ComplyChainCard
            contractDataId={contract.id}
            title={
              contract.attributes.contract_definition.data.attributes.title
            }
            shortDescription={
              contract.attributes.contract_definition.data.attributes
                .shortDescription
            }
            createdAt={contract.attributes.createdAt}
            updatedAt={contract.attributes.updatedAt}
            handleClose={() => {
              handleClose( contract.id );
            }}
          />
        </Grid>,
      );
    } );
  }

  let closedContractCounter = 0;
  const closedContractsJSX: JSX.Element[] = [];

  if ( contracts ) {
    Object.entries( contracts.closedContracts ).forEach( ( [ key, contract ] ) => {
      closedContractCounter++;

      closedContractsJSX.push(
        <Grid item xs={12} sm={6} lg={3} key={key}>
          <ComplyChainCard
            contractDataId={contract.id}
            title={
              contract.attributes.contract_definition.data.attributes.title
            }
            shortDescription={
              contract.attributes.contract_definition.data.attributes
                .shortDescription
            }
            createdAt={contract.attributes.createdAt}
            updatedAt={contract.attributes.updatedAt}
          />
        </Grid>,
      );
    } );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h4" component="div">
              {t( 'Dashboard ComplyChain - Demo GmbH' )}
            </Typography>
            <Divider sx={{ mt: 2, mb: 1 }} />
            <p>{t( 'Hallo Herr Demo,' )}</p>
            <p>
              {t( 'willkommen auf ihrem Dashboard.' )}{' '}
              {openContractCounter == 0 ||
                `${ t( 'Aktuell sind' ) } ${ openContractCounter } ${ t(
                  'Fragebögen in Bearbeitung.',
                ) } `}
              {t( 'Heute ist der' )} {DateHelper.simpleDateNow()}.
            </p>
          </Paper>
        </Grid>
        {openContractCounter === 0 ? (
          <></>
        ) : (
          <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h4" component="div">
                {t( 'Fragebögen in Bearbeitung' )}
              </Typography>
              <Divider sx={{ mt: 2, mb: 3 }} />
              <Grid container spacing={3} p={3}>
                {openContractsJSX}
              </Grid>
            </Paper>
          </Grid>
        )}
        {closedContractCounter === 0 ? (
          <></>
        ) : (
          <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h4" component="div">
                {t( 'Abgeschlossene Fragebögen' )} {/*(letzte 30 Tage)*/}
              </Typography>
              <Divider sx={{ mt: 2, mb: 3 }} />
              <Grid container spacing={3} p={3}>
                {closedContractsJSX}
              </Grid>
              {/*<Button
                sx={{ mt: 3 }}
                onClick={() => {
                  navigate( '/archive' );
                }}
              >
                Zum Archiv
              </Button>*/}
            </Paper>
          </Grid>
        )}
      </Grid>
    </Container>
  );
}
