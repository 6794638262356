import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';
import ContactCard from '../ContactCard/ContactCard';
import ApiHelper from '../../common/ApiHelper';
import { ContractPartner } from '../../common/ContractPartner';
import ContactDialog from '../Contacts/ContactDialog';

export default function PartyDialog( props: {
  title: string;
  actionText: string;
  isOpen: boolean;
  contractDataId: number;
  currentFirstParty: ContractPartner | null;
  currentSecondParty: ContractPartner | null;
  handleClose: () => void;
  handleAction: (
    firstPartyData: ContractPartner | null,
    secondPartyData: ContractPartner | null,
  ) => void;
  onPartiesUpdate: () => void;
} ): JSX.Element {
  const {
    title,
    actionText,
    isOpen,
    currentFirstParty,
    currentSecondParty,
    handleClose,
    handleAction,
    onPartiesUpdate,
  } = props;

  const [ contacts, setContacts ] = useState<
    { id: number; attributes: ContractPartner }[]
  >( [] );
  const [ firstPartyData, setFirstPartyData ] = useState<ContractPartner | null>(
    currentFirstParty,
  );
  const [ secondPartyData, setSecondPartyData ] =
    useState<ContractPartner | null>( currentSecondParty );

  const [ contactEditDialogOpen, setContactEditDialogOpen ] = useState( false );
  const [ editId, setEditId ] = React.useState<number | null>( null );

  const fetchContacts = () => {
    ApiHelper.listContacts().then( ( contactsResult ) => {
      const filteredResultData = contactsResult.data.filter(
        ( contact ) =>
          contact.attributes.contract_datum.data === null ||
          contact.attributes.contract_datum.data.id === props.contractDataId,
      );
      setContacts( filteredResultData );
    } );
  };

  useEffect( () => {
    fetchContacts();
  }, [] );

  useEffect( () => {
    setFirstPartyData( currentFirstParty );
  }, [ currentFirstParty ] );

  useEffect( () => {
    setSecondPartyData( currentSecondParty );
  }, [ currentSecondParty ] );

  const contactsMapped = contacts.map( ( contact ) => {
    return { id: contact.id, label: contact.attributes.alias };
  } );

  return (
    <>
      <ContactDialog
        contactDialogIsOpen={contactEditDialogOpen}
        setContactDialogIsOpen={setContactEditDialogOpen}
        refreshContacts={onPartiesUpdate}
        editId={editId}
      />
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <Grid container spacing={3} p={3}>
            <Grid item xs={12} lg={12}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                value={
                  firstPartyData
                    ? {
                      id: firstPartyData?.id,
                      label: firstPartyData?.alias,
                    }
                    : null
                }
                options={contactsMapped}
                sx={{ width: 300 }}
                isOptionEqualToValue={( option, value ) => option.id === value.id}
                renderInput={( params ) => (
                  <TextField {...params} label="Erste Partei" />
                )}
                onChange={( event, newValue ) => {
                  const partyId = newValue?.id ?? -1;
                  if ( partyId >= 0 ) {
                    ApiHelper.getContact( partyId ).then( ( result ) =>
                      setFirstPartyData( {
                        ...result.data.attributes,
                        id: partyId,
                      } ),
                    );
                  } else {
                    setFirstPartyData( null );
                  }
                }}
              />
            </Grid>
            {firstPartyData === null || (
              <Grid item xs={12} lg={12}>
                <ContactCard contractPartner={firstPartyData} />
                {firstPartyData?.contract_datum?.data !== null && (
                  <Button
                    onClick={() => {
                      setEditId( firstPartyData?.id ?? null );
                      setContactEditDialogOpen( true );
                    }}
                  >
                    Editieren
                  </Button>
                )}
              </Grid>
            )}
            <Grid item xs={12} lg={12}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                value={
                  secondPartyData
                    ? {
                      id: secondPartyData?.id,
                      label: secondPartyData?.alias,
                    }
                    : null
                }
                options={contactsMapped}
                sx={{ width: 300 }}
                isOptionEqualToValue={( option, value ) => option.id === value.id}
                renderInput={( params ) => (
                  <TextField {...params} label="Zweite Partei" />
                )}
                onChange={( event, newValue ) => {
                  const partyId = newValue?.id ?? -1;
                  if ( partyId >= 0 ) {
                    ApiHelper.getContact( partyId ).then( ( result ) => {
                      setSecondPartyData( {
                        ...result.data.attributes,
                        id: partyId,
                      } );
                    } );
                  } else {
                    setSecondPartyData( null );
                  }
                }}
              />
            </Grid>
            {secondPartyData === null || (
              <Grid item xs={12} lg={12}>
                <ContactCard contractPartner={secondPartyData} />
                {secondPartyData?.contract_datum?.data !== null && (
                  <Button
                    onClick={() => {
                      setEditId( secondPartyData?.id ?? null );
                      setContactEditDialogOpen( true );
                    }}
                  >
                    Editieren
                  </Button>
                )}
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!firstPartyData || !secondPartyData}
            onClick={() => handleAction( firstPartyData, secondPartyData )}
            autoFocus
          >
            {actionText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
