import downloadFile from './downloadFile';
import LocalStorageHelper from './LocalStorageHelper';
import NotificationHelper from './NotificationHelper';
import Settings from './Settings';
import { useTranslation } from 'react-i18next';

export default function downloadContract(
  contractDataId: number,
  filename: string,
): void {
  //const { t, i18n } = useTranslation();

  fetch( `${ Settings.apiUrl }/generate-by-data/${ contractDataId }`, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      Authorization: 'Bearer ' + LocalStorageHelper.get( 'token' ),
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  } )
    .then( ( resolve ) => {
      if ( !resolve.ok ) {
        throw new Error( 'Dokument konnte nicht generiert werden.' );
      }

      return resolve.blob();
    } )
    .then( ( pdfData: Blob ) => {
      downloadFile( pdfData, `${ filename }.docx` );
    } )
    .catch( ( error ) => {
      NotificationHelper.error(
        //t(
        'An error occurred. Please check inputs and try again. ' +
          'If the problem persists, please contact us if the problem persists',
        //),
        error,
      );
    } );
}
