import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import { ContractPartner } from '../../common/ContractPartner';

export default function ContactCard( props: {
  contractPartner: ContractPartner;
} ): JSX.Element {
  const { t, i18n } = useTranslation();

  let contractPartner = props.contractPartner;
  let bottomPart: JSX.Element = <></>;

  if ( !contractPartner ) {
    contractPartner = {
      id: -1,
      alias: '',
      contract_datum: { data: null },
      data: [
        {
          __component: 'contact.legal-entity',
          company: t( 'Kein Vertragspartner ausgewählt' ),
          streetHouseNumber: '',
          zipCity: '',
          representative: '',
          functionRepresentative: '',
        },
      ],
    };
  }

  if ( contractPartner.data[ 0 ].__component === 'contact.legal-entity' ) {
    let functionRepresentative = '';

    if ( contractPartner.data[ 0 ].functionRepresentative ) {
      functionRepresentative = `(${ contractPartner.data[ 0 ].functionRepresentative })`;
    }

    bottomPart = (
      <>
        <Typography variant="h5" component="div">
          {contractPartner.data[ 0 ].company}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {contractPartner.data[ 0 ].representative} {functionRepresentative}
        </Typography>
        <Typography variant="body2">
          {contractPartner.data[ 0 ].streetHouseNumber}
          <br />
          {contractPartner.data[ 0 ].zipCity}
        </Typography>
      </>
    );
  } else if ( contractPartner.data[ 0 ].__component === 'contact.natural-person' ) {
    // TODO: ContactCard for natural person
    bottomPart = (
      <>
        <Typography variant="h5" component="div">
          {contractPartner.alias}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          adjective
        </Typography>
        <Typography variant="body2">
          well meaning and kindly.
          <br />
          {'"a benevolent smile"'}
        </Typography>
      </>
    );
  }

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {contractPartner.alias}
        </Typography>
        {bottomPart}
      </CardContent>
    </Card>
  );
}
