import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import ContractForm from './components/ContractForm/ContractForm';

import reducer from './store/reducer';
import SignIn from './components/SignIn/SignIn';
import { AuthContext, fakeAuthProvider } from './auth';
import { CssBaseline } from '@mui/material';
import { Theme } from './theme';
import Dashboard from './components/Dashboard/Dashboard';
import Archive from './components/Archive/Archive';
import LocalStorageHelper from './common/LocalStorageHelper';
import ApiHelper from './common/ApiHelper';
import Contacts from './components/Contacts/Contacts';

const store = createStore( reducer, composeWithDevTools() );

function AuthProvider( { children }: { children: React.ReactNode } ) {
  const [ user, setUser ] = React.useState<string | null>(
    LocalStorageHelper.get( 'user' ),
  );

  if (
    user &&
    LocalStorageHelper.get( 'user' ) &&
    LocalStorageHelper.get( 'token' )
  ) {
    ApiHelper.checkToken( () => {
      setUser( null );
      LocalStorageHelper.remove( 'user' );
      LocalStorageHelper.remove( 'token' );
    } );
  }

  const signin = ( newUser: string, callback: VoidFunction ) => {
    return fakeAuthProvider.signin( () => {
      LocalStorageHelper.set( 'user', newUser );
      setUser( newUser );
      callback();
    } );
  };

  const signout = ( callback: VoidFunction ) => {
    return fakeAuthProvider.signout( () => {
      LocalStorageHelper.remove( 'user' );
      setUser( null );
      callback();
    } );
  };

  const value = { user, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuth() {
  return React.useContext( AuthContext );
}

function RequireAuth( { children }: { children: JSX.Element } ) {
  const auth = useAuth();
  const location = useLocation();

  if ( !auth.user ) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }

  return children;
}

const container = document.getElementById( 'root' );
if ( container !== null ) {
  const root = createRoot( container );
  root.render(
    <React.StrictMode>
      <AuthProvider>
        <BrowserRouter>
          <Provider store={store}>
            <CssBaseline />
            <Theme>
              <Routes>
                <Route path="/signin" element={<SignIn />} />
                <Route
                  path="/"
                  element={
                    <RequireAuth>
                      <App />
                    </RequireAuth>
                  }
                >
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/archive" element={<Archive />} />
                  <Route path="contract" element={<Outlet />}>
                    <Route path=":dataId" element={<ContractForm />}>
                      <Route path=":page" element={<ContractForm />} />
                    </Route>
                  </Route>
                  <Route path="/contacts" element={<Contacts />} />
                  <Route
                    path="*"
                    element={
                      <main style={{ padding: '1rem' }}>
                        <p>There is nothing here!</p>
                      </main>
                    }
                  />
                </Route>
              </Routes>
            </Theme>
          </Provider>
        </BrowserRouter>
      </AuthProvider>
    </React.StrictMode>,
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
