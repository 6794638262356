//import { useTranslation } from 'react-i18next';

declare const clariusNotification: {
  pushInfo( message: string, additional: string | null ): void;
  pushSuccess( message: string, additional: string | null ): void;
  pushWarning( message: string, additional: string | null ): void;
  pushError( message: string, additional: string | null ): void;
};

class NotificationHelper {
  public static info( message: string, additional: string | null = null ): void {
    NotificationHelper.sendNotification( message, 'info', additional );
  }

  public static success(
    message: string,
    additional: string | null = null,
  ): void {
    NotificationHelper.sendNotification( message, 'success', additional );
  }

  public static warning(
    message: string,
    additional: string | null = null,
  ): void {
    NotificationHelper.sendNotification( message, 'warning', additional );
  }

  public static error( message: string, additional: string | null = null ): void {
    NotificationHelper.sendNotification( message, 'error', additional );
  }

  public static silentError( ...args: unknown[] ): void {
    console.error( args );
  }

  private static sendNotification(
    message: string,
    type: 'info' | 'success' | 'warning' | 'error' = 'info',
    additional: string | null = null,
  ): void {
    //const { t, i18n } = useTranslation();

    if ( typeof clariusNotification !== 'undefined' ) {
      switch ( type ) {
      case 'success':
        clariusNotification.pushSuccess( message, additional );
        break;
      case 'warning':
        clariusNotification.pushWarning( message, additional );
        break;
      case 'error':
        clariusNotification.pushError( message, additional );
        break;
      default:
      case 'info':
        clariusNotification.pushInfo( message, additional );
        break;
      }
    } else {
      switch ( type ) {
      case 'success':
        alert( `${ 'Success' }:\n${ message }` );
        console.log( 'Clarius Document Generator:', message );
        if ( additional !== null ) {
          console.log( additional );
        }
        break;
      case 'warning':
        alert( `${ 'Warning' }:\n${ message }` );
        console.warn( 'Clarius Document Generator:', message );
        if ( additional !== null ) {
          console.warn( additional );
        }
        break;
      case 'error':
        alert( `${ 'Error' }:\n${ message }` );
        console.error( 'Clarius Document Generator:', message );
        if ( additional !== null ) {
          console.error( additional );
        }
        break;
      default:
      case 'info':
        alert( `${ 'Info' }:\n${ message }` );
        console.info( 'Clarius Document Generator:', message );
        if ( additional !== null ) {
          console.info( additional );
        }
        break;
      }
    }
  }
}

export default NotificationHelper;
